import { isInViewport } from './util';
import { RoughAnnotation } from 'rough-notation/lib/model';
import Highlight from './Highlight';
import { THighlight, WidgetConfig } from 'src/types';
export interface ProcessingStrategy<
  T extends {
    highlights?: Highlight[];
    annotations?: { [key: string]: RoughAnnotation[] };
    cuepoints?: unknown[];
  },
> {
  process(
    highlights: THighlight[],
    config: WidgetConfig,
    cuepoints: unknown[],
    options?: {
      shownHighlights?: Set<string>;
      createAnnotations?: boolean;
      scrollHandler?: (highlight: Highlight) => void;
    },
  ): T;
}

export class NewProcessingStrategy
  implements ProcessingStrategy<{ highlights: Highlight[]; cuepoints?: any[] }>
{
  private highlights: Highlight[] = [];
  public process(highlights, config, cuepoints?, options?) {
    // Create individual highlights for each selector
    const clonedHighlights: THighlight[] = [];
    for (const highlight of highlights) {
      if (highlight.selectors) {
        let i = 0;
        for (const selector of highlight.selectors) {
          clonedHighlights.push({
            ...highlight,
            selector,
            id: highlight.id + `-${i++}`,
            nInSeries: i,
            original_id: highlight.id,
          });
        }
      } else {
        clonedHighlights.push({ ...highlight, original_id: highlight.id, nInSeries: 1 });
      }
    }
    this.highlights = clonedHighlights
      .map((highlight) => {
        const cuepoint = cuepoints?.find(
          (cuepoint) => cuepoint.highlight_id === highlight.original_id,
        );
        // If the cuepoint is not found, we don't create the highlight
        if (!cuepoint) return null;
        try {
          return new Highlight(highlight, config, cuepoint, options);
        } catch (e) {
          console.warn(e);
          return null;
        }
      })
      .filter((highlight) => highlight !== null);
    this.setListeners(options);
    this.highlights.sort((a, b) => a.time - b.time);
    return { highlights: this.highlights };
  }

  private setListeners({ scrollHandler }: { scrollHandler: EventListener }) {
    const { highlights } = this;
    const cuepointReachedHandler = ({ highlightElementId }) => {
      const highlight = highlights.find(
        (highlight) => highlight.id === highlightElementId,
      );
      if (!highlight) {
        // The error of missing highlights is handled in process()
        return;
      }
      const { element } = highlight;
      if (!element) {
        throw new Error('Element not found');
      }
      window.removeEventListener('scroll', scrollHandler);
      // Only scroll ones to the first element
      // in a highlight in a series of highlights
      // (i.e., ones that include multiple rects)
      if (highlight.nInSeries === 1) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
      // auto dock on left corner
      // disabled for pixmania
      // const container = document.querySelector(
      //   '.' + HOST_CLASSNAME,
      // ) as HTMLElement;
      // const draggable = container.shadowRoot?.querySelector(
      //   '.react-draggable',
      // ) as HTMLElement;
      // if (!draggable) throw new Error('Could not find the draggable element');
      // container.style.bottom = '10px';
      // container.style.left = '10px';
      // container.style.transition = 'all 0.5s ease-in-out';
      // draggable.style.transition = 'transform 0.5s ease-in-out';
      // setTimeout(() => {
      //   draggable.style.transition = 'none';
      // }, 500);

      let scrollTimeout;
      // We need to wait until the scroll is finished before starting the animation
      function showAfterScroll() {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
          if (!highlight) return;
          window.removeEventListener('scroll', showAfterScroll);
          highlight.showAnnotation();
          window.addEventListener('scroll', scrollHandler);
        }, 0);
      }
      if (!document.hidden && document.visibilityState === 'visible') {
        if (isInViewport(element)) {
          highlight.showAnnotation();
          window.removeEventListener('scroll', showAfterScroll);
        } else {
          addEventListener('scroll', showAfterScroll);
        }
      } else {
        highlight.showAnnotation();
      }
    };
    // @ts-expect-error Legacy code
    window.cuepointReachedHandler = cuepointReachedHandler;
    document.addEventListener(
      'cuepointReached',
      cuepointReachedHandler as unknown as EventListener,
      false,
    );
  }
}
