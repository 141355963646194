import { CSSProperties, useContext } from 'preact/compat';
import { DEFAULT_WIDGET_STYLE } from 'src/constants';
import { globalContext } from 'src/globalContext';
import useCurrentBreakpoint from 'src/hooks/useCurrentBreakpoint';

export default function Overlay({
  color,
  left,
  top,
  right,
  style,
  id,
}: Readonly<{
  id: string;
  color?: string;
  left?: number;
  top?: number;
  right?: number;
  style?: CSSProperties;
}>) {
  const { config } = useContext(globalContext);
  const currentBreakpoint = useCurrentBreakpoint(config);
  const currentStyle = config?.styles?.widget?.[currentBreakpoint] ?? {
    width: DEFAULT_WIDGET_STYLE.width,
    height: DEFAULT_WIDGET_STYLE.height,
  };
  const { width, height } = currentStyle;
  // 20% of the width
  const offset = parseInt(width) * 0.2;
  const overlayStyle = {
    width: parseInt(width) - offset,
    height: parseInt(height) - offset,
  };
  // Offsets for the tripled overlay spots
  const offsets = [{ left: offset }, { right: offset }, { top: offset }];
  return (
    <>
      <style>
        {`
#${id}::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${color};
        z-index: 19;
        pointer-events: none;
        filter: blur(${currentBreakpoint === 'small' ? '5px' : '17px'});
        }
      `}
      </style>
      <div
        id={id}
        style={{
          backgroundColor: 'transparent',
          position: 'absolute',
          left: left,
          top: top,
          right: right,
          pointerEvents: 'none',
          zIndex: 19,
          borderRadius: '50%',
          ...style,
          width: overlayStyle.width,
          height: overlayStyle.height,
          ...offsets[id],
        }}
      />
    </>
  );
}
