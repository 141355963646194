import { WidgetConfig } from 'src/types';

export const Bubble = ({
  mobile,
  show,
  config,
}: {
  mobile: boolean;
  show: boolean;
  config: WidgetConfig;
}) => {
  const subtitle = config?.components?.bubble?.subtitle;

  return (
    <div
      id="bubble"
      style={{
        backgroundColor: '#000000',
        borderRadius: '50px',
        position: 'absolute',
        right: mobile ? '80%' : '110%',
        top: mobile ? '35%' : '30%',
        padding: mobile ? '8px 12px' : '12px 16px',
        color: '#ffffff',
        opacity: show ? 1 : 0,
        clip: show
          ? 'rect(auto, auto, auto, 0)'
          : 'rect(auto, auto, auto, 400px)',
        transform: show ? 'translateX(0)' : 'translateX(30px)',
        transition:
          'clip 0.5s ease-out, opacity .5s ease-in-out, transform .3s ease-in-out',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <div
        style={{
          fontSize: mobile ? '11px' : '14px',
          textAlign: 'center',
        }}
      >
        <div style={{ fontWeight: 'bold' }}>
          {config?.components?.bubble?.title[config.language]}
        </div>
        {subtitle && (
          <div style={{ opacity: 0.5, textAlign: 'center' }}>
            {config?.components?.bubble?.subtitle?.[config.language]}
          </div>
        )}
      </div>
    </div>
  );
};
