export function Controls({
  toggleVideo,
  playButtonText,
}: Readonly<ControlsProps>) {
  return (
    <div>
      <div style={{ display: 'flex', gap: '7px', marginLeft: '3px' }}>
        <div
          style={{
            width: 17,
            height: 19,
            position: 'relative',
            top: 2,
          }}
        >
          <svg
            viewBox="0 0 17 90"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.12935 2.19013L0.640021 0.0626073C0.361109 -0.107452 0 0.088961 0 0.410725V18.5784C0 18.8938 0.348399 19.0911 0.627546 18.9338L4.11688 16.9676C4.24673 16.8944 4.32677 16.7589 4.32677 16.6122V2.53825C4.32677 2.39661 4.25212 2.26499 4.12935 2.19013Z"
              fill="white"
            />
            <path
              d="M0 18.3337V14.476L3.80895 12.3687C4.07888 12.2193 4.4214 12.314 4.57177 12.5795L6.01945 15.1355C6.16528 15.3929 6.07676 15.7172 5.81928 15.8691L0.846312 18.8021C0.474213 19.0215 0 18.7591 0 18.3337Z"
              fill="white"
            />
            <path
              d="M0 18.5825V14.4623L12.749 7.37185C12.8754 7.30156 13.03 7.30047 13.1574 7.36901L16.6923 9.27032C16.9794 9.42475 16.9825 9.82763 16.6978 9.98619L0.625737 18.939C0.346599 19.0944 0 18.897 0 18.5825Z"
              fill="white"
            />
            <path
              d="M9.07036 9.10589L10.5574 6.48446C10.7083 6.21846 11.0519 6.12428 11.3218 6.27494L16.3731 9.09405C16.7489 9.30376 16.7489 9.8342 16.3733 10.0441L12.9167 11.9747L9.2719 9.84071C9.01316 9.68923 8.92394 9.36393 9.07036 9.10589Z"
              fill="white"
            />
            <path
              d="M6.58789 8.01146V4.18412C6.58789 3.8681 6.93752 3.67085 7.21667 3.82938L16.7833 9.26245C17.0639 9.42181 17.0608 9.81969 16.7778 9.97497L13.0083 12.0428L6.79005 8.36243C6.66457 8.28818 6.58789 8.15504 6.58789 8.01146Z"
              fill="white"
            />
          </svg>
        </div>
        <button
          onClick={toggleVideo}
          style={{
            padding: 0,
            margin: 0,
            fontWeight: 'bold',
            color: 'white',
            fontSize: '16px',
            letterSpacing: '-0.1px',
            outline: 'none',
            border: 'none',
          }}
        >
          {playButtonText}
        </button>
      </div>
    </div>
  );
}
export interface ControlsProps {
  toggleVideo: () => void;
  playButtonText?: string;
}
