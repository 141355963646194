import { render } from 'preact/compat';
import Widget from './components/Widget';
import Highlight from './lib/Highlight';
import { RoughAnnotation } from 'rough-notation/lib/model';
import { selectStrategies } from './lib/util';
import { HOST_CLASSNAME } from './constants';
if (!document.querySelector(`.${HOST_CLASSNAME}`)) {
  const host = document.createElement('div');
  host.classList.add(HOST_CLASSNAME);
  host.style.position = 'fixed';
  host.style.zIndex = '9999';
  host.style.pointerEvents = 'none';
  document.body.append(host);
  const shadow = host.attachShadow({ mode: 'open' });

  const { dataRetrievalStrategy, processingStrategy } = selectStrategies();
  window['HeyGenius'] = window['HeyGenius'] ?? {};
  window['HeyGenius'].version = import.meta.env['VITE_APP_VERSION'];
  render(
    <Widget<{
      highlights?: Highlight[];
      annotations?: { [key: string]: RoughAnnotation[] };
    }>
      // projectId={window?.[HEY_GENIUS_FIELD]?.app_id}
      dataRetrievalStrategy={dataRetrievalStrategy}
      processingStrategy={processingStrategy}
    />,
    shadow,
  );
}
