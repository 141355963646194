import { RoughAnnotationType } from 'rough-notation/lib/model';

export const API_URL = import.meta.env['VITE_API_ROOT_URL'];
export const WIDGET_BASE = import.meta.env['VITE_WIDGET_BASE'];

export const DEFAULT_WIDGET_STYLE = {
  width: '200px', // Set desired width
  height: '200px',
  willChange: 'transform',
  zIndex: 20,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};
export const GENIUS_API_URL = `${API_URL}`;
export const HEY_GENIUS_FIELD = 'heygeniusSettings';
export const HOST_CLASSNAME = 'heygenius-container';
export const CLASSNAME_PREFIX = 'lfhg-'; // libertify heygenius

export const DEFAULT_ANNOTATION_OPTIONS = {
  animate: true,
  type: 'highlight' as RoughAnnotationType,
  color: 'rgba(255, 255, 0, 0.5)',
  stroke_width: 2,
  padding: 5,
};
