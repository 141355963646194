import { useMediaQuery } from '@uidotdev/usehooks';
import { WidgetConfig } from 'src/types';

export default function useCurrentBreakpoint(config?: WidgetConfig | null) {
  const isMediumDevice = useMediaQuery(
    `only screen and (min-width : ${config?.breakpoints?.medium})`,
  );
  const isLargeDevice = useMediaQuery(
    `only screen and (min-width : ${config?.breakpoints?.large})`,
  );

  let currentBreakpoint: 'small' | 'medium' | 'large' = 'small';
  if (isLargeDevice) {
    currentBreakpoint = 'large';
  } else if (isMediumDevice) {
    currentBreakpoint = 'medium';
  }
  if (!config) return 'small';
  return currentBreakpoint;
}
