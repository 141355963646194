declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag?: any;
  }
}
const configId = import.meta.env['VITE_GA_CONFIGURATION_ID'];
const isTrackingAllowed = import.meta.env['VITE_GA_ENABLE_TRACKING'] === 'true';

let isTrackingEnabled = false;
let baseEventParams: Record<string, string>;

export const analytics = () => {
  return {
    init: ({
      shouldTrack,
      eventParams,
    }: {
      shouldTrack: boolean;
      eventParams?: Record<string, string>;
    }) => {
      if (!shouldTrack || !isTrackingAllowed) {
        return;
      }
      isTrackingEnabled = true;
      if (eventParams) baseEventParams = eventParams;
      // keep page_view to have builtin page title in GA reports
      window?.gtag?.('config', configId, { send_page_view: true });
    },
    logEvent: async (eventName: string, eventParams?: object) => {
      if (!isTrackingEnabled) {
        return;
      }
      try {
        window?.gtag?.('event', eventName, {
          send_to: configId,
          ...eventParams,
          ...baseEventParams,
        });
      } catch (e) {
        console.error(e);
      }
    },
  };
};
