import { ComponentChildren, RefCallback } from 'preact';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useContext,
} from 'preact/compat';
import Visualizer from '@libertify.frontend/visualizer/lib/Visualizer';
import useCurrentBreakpoint from 'src/hooks/useCurrentBreakpoint';
import { globalContext } from 'src/globalContext';
import { DEFAULT_WIDGET_STYLE } from 'src/constants';
interface AvatarProps {
  mediaSrc: string;
  type?: string;
  mediaStyle: CSSProperties;
}
type AvatarComponentProps = {
  audioLink: string;
};

const AvatarComponent = forwardRef(function AvatarComponent(
  { audioLink }: AvatarComponentProps,
  myRef,
) {
  const { config } = useContext(globalContext);
  const widgetStyles = config?.styles?.widget;
  const currentBreakpoint = useCurrentBreakpoint(config);
  const breakpointConfig = widgetStyles?.[currentBreakpoint];
  let { width, height } = breakpointConfig;
  // Search each lower breakpoint if the larger one isn't defined, and fallback to the default if none are defined
  if (!width || !height) {
    ({ width, height } = widgetStyles?.['medium'] ??
      widgetStyles?.['small'] ?? {
        width: widgetStyles?.width ?? DEFAULT_WIDGET_STYLE.width,
        height: widgetStyles?.height ?? DEFAULT_WIDGET_STYLE.height,
      });
  }

  return (
    <Visualizer
      ref={(ref) => {
        (
          myRef as RefCallback<{
            play: () => void;
            pause: () => void;
          }>
        )(ref);
      }}
      width={width.replace('px', '')}
      height={height.replace('px', '')}
      audioLink={audioLink}
    />
  );
});

type Ref = {
  media?: HTMLAudioElement | HTMLVideoElement | null;
  visualizer?: {
    play: () => void;
    pause: () => void;
  };
};

const MediaElement = forwardRef(function MediaElement(
  {
    children,
    mediaStyle,
    type,
    ...props
  }: Readonly<
    {
      type: string;
      children: ComponentChildren;
      src: string;
    } & Omit<AvatarProps, 'mediaSrc'>
  >,
  avatarRef: ForwardedRef<Ref>,
) {
  if (type === 'visualizer') {
    return (
      <div>
        <AvatarComponent
          audioLink={props.src}
          ref={(ref) => {
            if (!ref) return;
            (avatarRef as (ref: Ref) => void)({
              visualizer: ref,
            });
          }}
        />
      </div>
    );
  } else if (type === 'video') {
    return (
      <div
        style={{
          overflow: 'hidden',
          borderRadius: '50%',
        }}
      >
        <video
          style={mediaStyle}
          ref={(ref) => {
            if (!ref) return;
            (avatarRef as (ref: Ref) => void)({
              media: ref,
            });
          }}
          {...props}
        >
          {children}
          <track kind="captions">Not implemented yet</track>
        </video>
      </div>
    );
  } else throw new Error('Invalid type');
});

export default forwardRef(function Avatar(
  { mediaSrc, type = 'video', mediaStyle }: Readonly<AvatarProps>,
  avatarRef: ForwardedRef<Ref>,
) {
  return (
    <MediaElement
      type={type}
      ref={avatarRef}
      mediaStyle={{
        width: '100%' /* Scale the video to fit the container */,
        height: 'auto',
        position: 'absolute',
        top: 0,
        ...mediaStyle,
      }}
      src={mediaSrc}
    >
      <source id="libertify-video" src={mediaSrc} type="video/mp4" />
      Your browser does not support the video tag.
    </MediaElement>
  );
});
