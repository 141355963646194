import camelCase from 'lodash.camelcase';
import { annotate } from 'rough-notation';
import { RoughAnnotation } from 'rough-notation/lib/model';
import { CLASSNAME_PREFIX, DEFAULT_ANNOTATION_OPTIONS } from 'src/constants';
import { getCoords } from 'src/lib/util';
import { THighlight, WidgetConfig } from 'src/types';
export default class NotationHighlight {
  static readonly classNamePrefix = `${CLASSNAME_PREFIX}hl-`;
  public id: string;
  public time: number;
  private annotation?: RoughAnnotation & {
    shown?: boolean;
    _svg?: HTMLElement;
  };
  public nInSeries: number;
  private prevTop?: number;
  public element?: HTMLElement;
  private startElement?: HTMLElement;
  private endElement?: HTMLElement;
  private stickyParent?: HTMLElement | null;
  private readonly getCoords = getCoords;
  public showAnnotation() {
    if (!this.annotation) {
      return this.log(new Error('Annotation not created yet'));
    }
    this.annotation.show();
    this.annotation.shown = true;
  }

  public removeAnnotation() {
    if (!this.annotation) {
      return this.log(new Error('Annotation not created yet'));
    }
    this.annotation.remove();
  }

  public hideAnnotation() {
    if (!this.annotation) {
      return this.log(new Error('Annotation not created yet'));
    }
    this.annotation.shown = false;
    this.annotation.hide();
  }

  public destroy() {
    if (!this.element) return;
    this.annotation?.remove();
    this.element.remove();
  }

  get isShowing() {
    return this.annotation?.isShowing();
  }

  public get highlightDetails() {
    let position;

    if (this.element) {
      position = this.getCoords(this.element);
    }

    return { highlight: this.highlight, position, element: this.startElement };
  }

  constructor(
    private readonly highlight: THighlight,
    private readonly config: WidgetConfig,
    // TODO: type properly when merged with develop
    private readonly cuepoint?: Record<string, unknown>,
    private readonly options?: {
      animate?: boolean;
      createAnnotations?: boolean;
    },
  ) {
    this.id = highlight.id;
    this.time = highlight.time ?? (cuepoint?.['start'] as number);
    this.nInSeries = highlight.nInSeries;
    this.createHighlightElement();
  }

  public move() {
    if (!this.element) return this.log(new Error('Element not found'));
    const style = this.calculatePosition();
    // let style;
    // fastdom.measure(() => {
    // style = this.calculatePosition();
    // });
    // fastdom.mutate(() => {
    // });
    this.setStyle(style);
    if (this.stickyParent && style.position === 'fixed') {
      const svg = this?.annotation?._svg;
      if (svg) {
        svg.style.position = 'fixed';
      }
    }
    const wasShown = this.annotation?.shown ?? false;
    if (wasShown) {
      this.showAnnotation();
    }
  }

  private calculatePosition(): Partial<CSSStyleDeclaration> {
    const { highlight } = this;
    const {
      selector: { start: startSelector },
    } = highlight;
    this.startElement = document.querySelector(startSelector) as HTMLElement;
    const endSelector = highlight.selector?.end;

    if (!this.startElement) {
      return this.log(
        new Error(`Element not found for highlight with id ${highlight.id}`),
      );
    }
    if (startSelector === 'body') {
      return this.log(
        new Error(
          `Cannot highlight the body element, check highlight with id ${highlight.id}`,
        ),
      );
    }
    // If there's no end selector, that means that we want to encircle the whole element, so just copy its bounding client rect.
    let endCoords;
    if (endSelector && endSelector !== startSelector) {
      this.endElement = document.querySelector(endSelector) as HTMLElement;
      endCoords = this.getCoords(this.endElement);
    }
    const elementNotFound = !this.startElement;
    if (elementNotFound) {
      return this.log(
        new Error(`Element not found for highlight with id ${highlight.id}`),
      );
      // Fallback to searching by text
      // this.searchElementByText();
    }
    const startCoords = this.getCoords(this.startElement);
    const startElementSize = this.startElement.getBoundingClientRect();
    const height = endCoords
      ? endCoords.top + endCoords.height - startCoords.top
      : startElementSize.height;
    // The width of the highlight is the maximum width
    const width = Math.max(
      startElementSize.width,
      this.endElement?.getBoundingClientRect().width ?? -Infinity,
    );
    const style = {
      position: 'absolute',
      top: startCoords.top + 'px',
      left: startCoords.left + 'px',
      height: height + 'px',
      width: width + 'px',
      zIndex: '9999',
      opacity: '0.3',
      pointerEvents: 'none',
    };
    // Adjusting for scrolling lag when highlighting a sticky element (or an element inside a sticky container);
    if (this.stickyParent) {
      const rect = this.stickyParent?.getBoundingClientRect();
      const computedTop = parseInt(getComputedStyle(this.stickyParent).top);
      if (rect.top === computedTop) {
        // Element behaves as fixed, set our highlight to fixed
        style.position = 'fixed';
        // And remove the scroll offset
        const newTop = Math.floor(parseInt(style.top) - window.scrollY);
        if (!this.prevTop) {
          this.prevTop = startCoords.top - window.scrollY;
          style.top = newTop + 'px';
        } else if (Math.abs(newTop - this.prevTop) > 1) {
          // If the difference is more than 1 pixel, adjust the top
          // prevents jank
          // TODO: There's still a slight position issue when scrolling
          // very fast, but we'll have to live with it for now
          this.prevTop = newTop;
          style.top = newTop + 'px';
        } else {
          style.top = this.prevTop + 'px';
        }
      }
    }
    return style;
  }

  private findStickyParent() {
    // recursively go over the parents and find the first sticky parent
    if (!this.startElement) return;
    let parent: HTMLElement | null = this.startElement;
    while (parent) {
      if (window.getComputedStyle(parent).position === 'sticky') {
        this.stickyParent = parent;
        return;
      }
      parent = parent.parentElement;
    }
  }

  private setStyle(style: Partial<CSSStyleDeclaration>) {
    const elementStyle: Partial<CSSStyleDeclaration | undefined> =
      this.element?.style;
    if (!elementStyle) this.log(new Error('Element style not found'));
    elementStyle.position = style.position;
    elementStyle.top = '0';
    elementStyle.left = '0';
    elementStyle.transform = `translate(${style.left}, ${style.top})`;
    elementStyle.height = style.height;
    elementStyle.width = style.width;
    elementStyle.zIndex = style.zIndex;
    elementStyle.pointerEvents = style.pointerEvents;
  }

  public createHighlightElement() {
    const { highlight, config } = this;
    const {
      selector: { start: startSelector },
    } = highlight;
    if (highlight.inline) {
      this.element = document.querySelector(startSelector) as HTMLElement;
    } else {
      const style = this.calculatePosition();
      const wrapper = document.createElement('div');
      this.element = wrapper;
      this.findStickyParent();
      this.setStyle(style);
      if (config.debug) {
        wrapper.style.border = '3px solid red';
      }
      document.body.appendChild(wrapper);
      const className = NotationHighlight.classNamePrefix + highlight.id;
      wrapper.classList.add(className);
    }

    // Convert the annotationOptions from the backend into the native camelCase to be used by the rough annotation lib
    const annotationOptionsFromCuepoint: Record<string, any> = {};
    if (this.cuepoint) {
      Object.entries(this.cuepoint?.['rough_notation'] as object).forEach(
        ([key, value]) =>
          (annotationOptionsFromCuepoint[camelCase(key)] = value),
      );
    }
    const annotationOptions = {
      ...DEFAULT_ANNOTATION_OPTIONS,
      ...highlight.annotation_options,
      ...annotationOptionsFromCuepoint,
    };

    highlight.annotation_options = annotationOptions;
    if (highlight.annotation_options.color === 'primary' && config.colors) {
      annotationOptions.color = config.colors.primary;
    } else if (
      highlight.annotation_options.color === 'secondary' &&
      config.colors
    ) {
      annotationOptions.color = config.colors.secondary;
    }
    annotationOptions.stroke_width = annotationOptions.stroke_width || 10;
    annotationOptions.padding = annotationOptions.padding || 5;
    // annotationOptions.type = 'box';
    // alternate with forced boxes if larger than half screen width DLY

    if (
      ((parseInt(this.element.style.width) > document.body.clientWidth / 2 &&
        parseInt(this.element.style.height) > 30) ||
        parseInt(this.element.style.height) > 45) && // high areas
      !annotationOptions.protected_type
    ) {
      annotationOptions.type = 'bracket';
      annotationOptions.brackets = ['right', 'left'];
    }
    if (
      annotationOptions.color.length === 7 &&
      annotationOptions.type === 'highlight'
    ) {
      annotationOptions.color += '22'; // force low opacity
    }

    // annotationOptions.type = 'box';
    if (config.debug) console.debug(annotationOptions);
    if (this.options?.createAnnotations !== false) {
      this.annotation = annotate(this.element, annotationOptions);
    }
    highlight.annotation_options = annotationOptions;
  }
  private log(error): never {
    if (this.config.debug) {
      console.error(error);
    }
    throw error;
  }
}
