import { HEY_GENIUS_FIELD } from 'src/constants';
import {
  ApiDataRetrievalStrategy,
  LocalDataRetrievalStrategy,
} from './DataRetrievalStrategy';
import { NewProcessingStrategy } from './ProcessingStrategy';
import { LegacyProcessingStrategy } from './LegacyProcessingStrategy';

export function isPointInCircle(Px, Py, Cx, Cy, radius) {
  // Calculate the distance between the point and the center of the circle
  const distance = Math.sqrt((Px - Cx) ** 2 + (Py - Cy) ** 2);

  // Check if the distance is less than or equal to the radius
  return distance <= radius;
}
export function getCoordsInElement(
  target: HTMLElement,
  e: MouseEvent | TouchEvent,
) {
  const rect = target.getBoundingClientRect();
  if (window.TouchEvent && e instanceof TouchEvent) {
    const touch = e.touches[0];
    const x = touch.clientX - rect.left; //x position within the element.
    const y = touch.clientY - rect.top; //y position within the element.
    return { x, y };
  } else {
    const x = e.clientX - rect.left; //x position within the element.
    const y = e.clientY - rect.top; //y position within the element.
    return { x, y };
  }
}
export const isInViewport = function (elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};
// Get the coordinates from an element. Used for calculating the position and dimensions of the absolutely positioned element that is used to render the RoughNotation annotation.
export function getCoords(elem: HTMLElement) {
  const box = elem.getBoundingClientRect();

  const top = box.top + window.scrollY;
  const left = box.left + window.scrollX;
  const { x, y } = box;
  return {
    top: Math.round(top),
    left: Math.round(left),
    x,
    y,
    width: box.width,
    height: box.height,
  };
}

export function selectStrategies() {
  if (window?.[HEY_GENIUS_FIELD].product_sku) {
    return {
      processingStrategy: new NewProcessingStrategy(),
      dataRetrievalStrategy: new ApiDataRetrievalStrategy(
        window?.[HEY_GENIUS_FIELD].datasource_base,
      ),
    };
  } else {
    return {
      processingStrategy: new LegacyProcessingStrategy(),
      dataRetrievalStrategy: new LocalDataRetrievalStrategy(),
      // window?.[HEY_GENIUS_FIELD].app_id,
    };
  }
}
