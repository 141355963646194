import { CLASSNAME_PREFIX } from "src/constants";

export default {
  'playstation-5-slim-standard-246055.html': [
    // {
    //   id: 4,
    //   time: '2',
    //   annotationOptions: {
    //     type: 'underline',
    //     color: 'secondary',
    //     multiline: true,
    //   },
    //   text: [
    //     'Maximisez vos sessions de jeu avec des temps de chargement quasi instantanés pour les jeux PS5™ installés.',
    //   ],
    //   multiline: true,
    // },
    {
      id: 1,
      time: '2',
      // selector: '.item-productTitle',
      text: ['Sony - PS5 Slim 1 To'],

      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: true,
      },
      multiline: true,
    },
    {
      id: 2,
      time: '2',
      selector: '.item-photo-slider',
      annotationOptions: {
        type: 'box',
        color: 'primary',
        padding: [-10, 5, 5, 5],
        strokeWidth: 2,
      },
    },
    {
      id: 3,
      time: '11',
      selector:
        '#item-carac > div:nth-of-type(1) > div > div > div > div > ul > li:nth-of-type(5)',
      annotationOptions: {
        type: 'box',
        color: 'primary',
        padding: [15, 15, 0, 15],
      },
    },
    {
      id: 4,
      time: '11',
      text: ['358 x 216 x 96 mm'],
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        padding: [15, 15, 0, 15],
        multiline: true,
      },
      multiline: true,
    },
    {
      id: 9,
      time: '17',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: true,
      },
      text: [
        'Disque SSD ultra haute vitesse',
        'Maximisez vos sessions de jeu avec des temps de chargement quasi instantanés pour les jeux PS5™ installés.',
      ],
      multiline: true,
    },
    {
      id: 11,
      time: '27',
      selector: "[data-idx='Hogwarts Legacy']",
      annotationOptions: {
        type: 'circle',
        color: 'primary',
      },
    },
    {
      id: 12,
      time: '32',
      selector:
        '#item-reviews > div > div > div > div > div.col-12.col-md-3.sticky-top > div > div.d-flex.w-100.flex-md-column.align-items-center.justify-content-start.justify-content-md-center > div.pixel-30.pixel-md-50.font-600.mr-2.mr-md-0',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      id: 13,
      time: '37',
      selector: '[data-resume="to_pay_monthly_default"]',
      annotationOptions: {
        type: 'box',
        color: 'primary',
      },
    },
    {
      id: 20,
      time: '45.95615',
      selector: '.keyShipping',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      id: 30,
      time: '46.3',
      text: ['garantie constructeur de 24 mois'],
      annotationOptions: {
        type: 'highlight',
        color: 'primary',
        multiline: true,
      },
      multiline: true,
    },
  ],
  'galaxy-z-flip6-5g-512-go-vert-deau-debloque-295126.html': [
    {
      time: '1',
      selector: '.item-photo-slider .img-cover',
      id: '1',
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        strokeWidth: 5,
        padding: -2,
      },
    },
    {
      time: '7.7',
      selector:
        '#item-carac > div:nth-child(4) > div > div > div > div > ul > li:nth-child(3)',
      id: '2',
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        padding: [15, 15, 0, 15],
      },
    },
    {
      time: '16.39',
      id: '4',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-of-type(7) > ul > li:nth-of-type(2)',
      annotationOptions: {
        type: 'highlight',
        color: 'primary',
      },
    },
    {
      time: '16.39',
      id: '4',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-of-type(7) > ul > li:nth-of-type(4)',
      annotationOptions: {
        type: 'highlight',
        color: 'primary',
      },
    },
    {
      id: '6',
      time: '18.5',
      selector:
        '.item-carac-details .carac-list li:nth-of-type(9) li:last-child',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        animationDuration: 1000,
      },
    },
    {
      id: '7',
      time: '24',
      selector: '.item-carac-details .carac-list li:nth-of-type(6)',
      annotationOptions: {
        type: 'box',
        color: 'primary',
        animationDuration: 1200,
        padding: [15, 15, 0, 15],
      },
    },
    {
      id: '8',
      time: '28.7',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-of-type(5) > ul > li:nth-of-type(2)',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      id: '9',
      time: '28.7',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-of-type(5) > ul > li:nth-of-type(3)',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      id: '10',
      time: '36',
      selector:
        '#item-cta > div.bloc-element.mt-3.mt-md-0 > div.variations.d-flex.flex-column > div.order-2.product-variations.mt-3 > div.state.pixel-11.pixel-md-13.mt-2',
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        multiline: true,
      },
    },
    {
      id: 11,
      time: 40,
      selector:
        "div[data-current='offer'] div:nth-of-type(3) div:nth-of-type(6)",
      annotationOptions: { type: 'box', color: 'primary' },
    },
    {
      id: '12',
      time: '52',
      selector: '.key.keyShipping',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: true,
      },
    },
    // {
    //   "id"
    // }
  ],
  'galaxy-z-flip6-5g-256-go-gris-debloque-295132.html': [
    {
      time: '1',
      selector: '.item-photo-slider .img-cover',
      id: '1',
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        strokeWidth: 5,
        padding: -2,
      },
    },
    {
      time: '13',
      selector:
        '#item-carac > div:nth-child(4) > div > div > div > div > ul > li:nth-child(3)',
      id: '2',
      annotationOptions: {
        type: 'box',
        color: 'primary',
        padding: [15, 15, -15, 15],
      },
    },
    {
      time: '13',
      selector:
        '#item-carac > div:nth-child(4) > div > div > div > div > ul > li:nth-of-type(3) > ul > li:nth-of-type(3) > div.ml-auto.pixel-12.pixel-md-13.text-right',
      id: '3',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      time: '27',
      id: '4',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-of-type(7) > ul > li:nth-of-type(2)',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      time: '27',
      id: '5',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-of-type(7) > ul > li:nth-of-type(4)',
      annotationOptions: {
        type: 'highlight',
        color: 'primary',
      },
    },
    {
      id: '6',
      time: '30',
      selector:
        '.item-carac-details .carac-list li:nth-of-type(9) li:last-child',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        animationDuration: 1000,
      },
    },

    {
      id: '15',
      time: '36',
      selector: '.item-carac-details .carac-list li:nth-of-type(6)',
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        animationDuration: 1200,
        padding: [15, 15, 0, 15],
      },
    },
    {
      id: '17',
      time: '46',
      selector:
        '#item-carac > div:nth-of-type(2) > div > div > div > div > ul > li:nth-child(6) > ul > li:nth-of-type(2)',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
    {
      id: '18',
      time: '55',
      selector:
        '#item-cta > div.bloc-element.mt-3.mt-md-0 > div.variations.d-flex.flex-column > div.order-2.product-variations.mt-3 > div.state.pixel-11.pixel-md-13.mt-2',
      annotationOptions: {
        type: 'box',
        color: 'primary',
        padding: [5, 15, 15, 15],
      },
    },

    {
      id: '19',
      time: '68',
      selector:
        '#item-cta > div:nth-of-type(2) > div:nth-child(3) > div > div:nth-of-type(6)',
      annotationOptions: { type: 'box', color: 'secondary' },
    },
    {
      id: '21',
      time: '70',
      selector:
        '#item-cta > div:nth-of-type(2) > div:nth-child(3) > div > div:nth-of-type(1)',
      annotationOptions: { type: 'box', color: 'primary' },
    },
    {
      id: '23',
      time: '83',
      selector: '.keyShipping',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
      },
    },
  ],
  'plan-epargne-retraite-individuel-perin-matla': [
    {
      id: 161,
      time: '90',
      // time: "2",
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        multiline: false,
        padding: [2, 75, 50, 2],
      },
      absolute: {
        start:
          '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(6) > div:nth-child(3) > div > div.c-card__content > div:nth-of-type(3) > div:nth-of-type(3) > div > div.c-business-details__content-left',
        end: '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(6) > div:nth-of-type(3) > div > div.c-business-details.c-business-details--highlighted > div > div.c-business-details__content-left > p.u-color-big-stone.u-text-size-xs.u-nomargin',
      },
    },
    {
      id: 162,
      time: '92',
      // time: "2",
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        multiline: false,
      },
      selector:
        '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(6) > div:nth-of-type(3) > div > div.c-card__content > div:nth-of-type(4) > div > div > div.c-business-details__content-right > span',
    },
    {
      id: 169,
      time: '100',
      // time: "3",
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: false,
      },
      textOnly: true,
      selector: '#free-details > div.u-text-center > h2',
    },
    {
      id: 170,
      time: '104',
      // time: "3",
      annotationOptions: {
        type: 'highlight',
        color: 'primary',
        multiline: false,
      },
      textOnly: true,
      selector: '#free-details > div.u-text-center > p:nth-of-type(1)',
    },
    // //#free-details > div.u-text-center > p:nth-child(3)
    {
      id: 180,
      time: '104',
      // time: "3",
      annotationOptions: {
        type: 'highlight',
        color: 'primary',
        multiline: false,
      },
      textOnly: true,
      selector: '#free-details > div.u-text-center > p:nth-of-type(2)',
    },
    // #main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(9) > div.c-description > div > div > div.c-description__body
    {
      id: 190,
      // time: "4",
      time: '112',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: false,
      },
      selector:
        '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(9) > div.c-description > div > div > div.c-description__body > p:nth-of-type(1)',
    },
    {
      id: 200,
      time: '112',
      // time: "4",
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: false,
      },
      selector:
        '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(9) > div.c-description > div > div > div.c-description__body > p:nth-of-type(2)',
    },
    {
      id: 210,
      time: '112',
      // time: "4",
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: false,
      },
      selector:
        '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(9) > div.c-description > div > div > div.c-description__body > p:nth-of-type(3)',
    },
    {
      id: 220,
      // time: "4",
      time: '112',
      annotationOptions: {
        type: 'highlight',
        color: 'secondary',
        multiline: false,
      },
      textOnly: true,
      selector:
        '#main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(9) > div.c-description > div > div > div.c-description__body > p:nth-of-type(4)',
    },
    // {
    //   // #main-content > div.l-basic-page.l-basic-page--full > section:nth-of-type(10)
    //   id: 230,
    //   // time: "5",
    //   time: '116',
    //   annotationOptions: {
    //     type: 'highlight',
    //     color: 'secondary',
    //     multiline: true,
    //   },
    //   text: [
    //     'Comme plus de 6 millions de clients, rejoignez-nous en quelques clics',
    //   ],
    //   multiline: true,
    // },
    {
      id: 240,
      // time: "2",
      time: '126',
      annotationOptions: {
        type: 'box',
        color: 'secondary',
        multiline: false,
      },
      selector:
        '#main-content > div.l-basic-page-header.l-basic-page--full > header > div.c-header > div.o-grid.o-grid--stretch.o-grid--large > div.o-grid__item > div > div.c-header__offer-card > div > div > div > div',
    },
    {
      id: 241,
      // time: "2",
      time: '126',
      annotationOptions: {
        type: 'box',
        color: 'primary',
        multiline: false,
      },
      selector: 'body > header > div > a',
    },
  ],
};

export const videos = {
  'plan-epargne-retraite-individuel-perin-matla': [
    'https://cdn.dev.libertify.com/env/demos/boursorama_avatar_20241009.mp4',
    'https://cdn.dev.libertify.com/env/demos/boursorama_avatar_20241010.mp4',
  ],
  'playstation-5-slim-standard-246055.html':
    'https://api.dev.libertify.com/media/projects/49333cb8-13c8-4082-b51a-80069ae670a0/publications/42913725',

  'galaxy-z-flip6-5g-512-go-vert-deau-debloque-295126.html':
    'https://api.dev.libertify.com/media/projects/b306a2a9-9f89-4121-95b2-c2b4085db70a/publications/0fa19f8f',
  'galaxy-z-flip6-5g-256-go-gris-debloque-295132.html':
    'https://api.dev.libertify.com/media/projects/6e275a94-62bf-4a34-9451-02dc43e18a2b/publications/84707db1',
};

export const colors = {
  'plan-epargne-retraite-individuel-perin-matla': {
    primary: '#00388344',
    secondary: '#ec077544',
    gradient: ['#ec0775', '#003883', '#b3d6fe'],
  },
  'playstation-5-slim-standard-246055.html': {
    primary: '#db068066',
    secondary: '#4ca82d66',
  },

  'galaxy-z-flip6-5g-512-go-vert-deau-debloque-295126.html': {
    primary: '#db068066',
    secondary: '#4ca82d66',
    gradient: ['#db0680', '#F6C032', '#3baf35'],
  },
  'galaxy-z-flip6-5g-256-go-gris-debloque-295132.html': {
    primary: '#db068066',
    secondary: '#4ca82d66',
    gradient: ['#db0680', '#F6C032', '#3baf35'],
  },
};

export const configs = {
  'plan-epargne-retraite-individuel-perin-matla': {
    selectedVideo: '0',
    widgetType: 'video',
    styles: {
      video: [
        { transform: 'translate(0, -30%) scale(1.5)' },
        { transform: 'translate(-7px, -96%) scale(2.6)' },
      ],
    },
    callToAction: {
      url: 'https://www.boursobank.com/ouvrir-un-compte',
      css: `
      .${CLASSNAME_PREFIX}cta {
      width: 100%; height: 100%;}
  .${CLASSNAME_PREFIX}cta svg  {
      width: 50px;
      height: 50px;
  }
      .${CLASSNAME_PREFIX}cta  {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      }
      .${CLASSNAME_PREFIX}cta p {
      font-size: 1.5em;
      }
      .${CLASSNAME_PREFIX}cta p:nth-of-type(2) {
      font-size: 1.2em;
    }
      .${CLASSNAME_PREFIX}cta a {
        text-decoration: none;
      }
`,
      get html() {
        return `<button class="${CLASSNAME_PREFIX}cta" target="_blank"><div>
        <p>SOUSCRIVEZ<br/>MAINTENANT</p>
        <p><a href="https://www.boursobank.com/ouvrir-un-compte">Cliquez ici</a></p>
        <!-- Icon by Dazzle UI (https://www.svgrepo.com/svg/533701/refresh-cw) -->
        <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
        <div class="refresh">
        <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg></div>
        </div></a>`;
      },
    },
  },
};

for (const [page, color] of Object.entries(colors)) {
  if (!configs[page]) {
    configs[page] = {};
  }
  configs[page].colors = color;
}
