import { useState, useRef, useEffect, useCallback } from 'preact/compat';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { CuepointReachedEvent } from 'src/events';
import { getCoordsInElement, isPointInCircle } from 'src/lib/util';
import Overlay from './Overlay';
import Highlight from '../lib/Highlight';
import { Controls } from './Controls';
import { DataRetrievalStrategy, THighlight, WidgetConfig } from 'src/types';
import { ProcessingStrategy } from 'src/lib/ProcessingStrategy';
import {
  DEFAULT_WIDGET_STYLE,
  HEY_GENIUS_FIELD,
  HOST_CLASSNAME,
} from 'src/constants';
import Avatar from './Avatar';
import { RoughAnnotation } from 'rough-notation/lib/model';
import { GlobalContextProvider } from 'src/globalContext';
import useCurrentBreakpoint from 'src/hooks/useCurrentBreakpoint';
import { Bubble } from './Bubble';
import { analytics } from 'src/services/analytics';
import InjectedStyles from 'src/lib/InjectedStyles';
import { PauseIcon } from './PauseIcon';
import { PlayIcon } from './PlayIcon';

type RefObject = {
  media?: HTMLAudioElement | HTMLVideoElement | null;
  visualizer: {
    play: () => void;
    pause: () => void;
    stop: () => void;
    duration: number;
  };
};

export default function Widget<
  T extends {
    highlights?: Highlight[];
    annotations?: { [key: string]: RoughAnnotation[] };
    cuepoints?: unknown[];
  },
>({
  // projectId,
  dataRetrievalStrategy,
  processingStrategy,
}: Readonly<{
  // projectId: string;
  dataRetrievalStrategy: DataRetrievalStrategy;
  processingStrategy: ProcessingStrategy<T>;
}>) {
  const avatarRef = useRef<RefObject | null>(null);
  const avatarRefCallback = useCallback((ref) => {
    avatarRef.current = ref;
    if (!animationFrameId.current) {
      animationFrameId.current = animate();
    }
  }, []);
  const [config, setConfig] = useState<WidgetConfig | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [mediaSrc, setMediaSrc] = useState('');
  const [displayedCurrentTime, setDisplayedCurrentTime] = useState(0);
  // Needed so that we can access it from rAF
  const mediaContext = useRef<{ currentTime: number; isPlaying: boolean }>({
    currentTime: 0,
    isPlaying: false,
  });
  const annotations = useRef<T | object>({});
  const nextCuepoint = useRef(0);
  const ticking = useRef(false);
  const [position, setPosition] = useState<{
    x: number;
    y: number;
    clickedInCircle: boolean;
  }>({
    x: 0,
    y: 0,
    clickedInCircle: false,
  });
  function updateCurrentTime(currentTime: number, delta: number) {
    if (mediaContext.current.isPlaying) {
      currentTime += delta / 1000;
      mediaContext.current.currentTime = currentTime;
      if (window?.[HEY_GENIUS_FIELD]?.debug) {
        setDisplayedCurrentTime(currentTime);
      }
    }
  }
  function stopAndRewind() {
    setIsPlaying(false);
    if (mediaContext.current.currentTime === 0) return;
    mediaContext.current.currentTime = 0;
    const media = avatarRef.current?.media;
    if (media) {
      media.currentTime = 0;
      media.pause();
    }
    const player = avatarRef.current?.visualizer;
    player?.stop();
    prevTimestamp.current = 0;
    nextCuepoint.current = 0; // replay highlights when replaying media
    cancelAnimationFrame(animationFrameId.current);
  }

  // Fetch highlights data and media data
  const highlights = useRef<Highlight[] | undefined>(undefined);
  const cuepoints = useRef<unknown[] | undefined>([]);
  const animationFrameId = useRef(0);
  const wasPlaying = useRef(false);
  const prevTimestamp = useRef(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const highlightsData = useRef<THighlight[]>([]);
  const playedTillTheEnd = useRef(false);
  const { currentTime } = mediaContext.current;

  let loopCount = 0;
  // Main loop of the widget that handles the cuepoints,
  // tracks the current time and reacts to it
  const mainLoop = useCallback((timestamp: number) => {
    const { currentTime } = mediaContext.current;
    const duration =
      avatarRef.current?.media?.duration ??
      avatarRef.current?.visualizer?.duration;
    if (!prevTimestamp.current) {
      prevTimestamp.current = timestamp;
    }
    const hls = highlights?.current;
    if (!hls) return;
    const delta = timestamp - prevTimestamp.current;
    if (duration) {
      if (playedTillTheEnd.current === false) {
        playedTillTheEnd.current = currentTime >= duration;
      }
      if (playedTillTheEnd.current) {
        stopAndRewind();
        // FIXME sent multiple times !!!
        // analytics().logEvent('media_stop', {
        //   media_url: mediaSrc,
        //   media_time: currentTime,
        //   media_duration: duration,
        //   highlight_index: nextCuepoint.current,
        // });
      } else updateCurrentTime(currentTime, delta);
    }
    if (currentTime >= hls?.[nextCuepoint.current]?.time) {
      document.dispatchEvent(
        CuepointReachedEvent(hls?.[nextCuepoint.current].id),
      );

      const maxBottomPositionIncreaser = window.innerWidth <= 768 ? 2 : 3;

      // Set the y to the bottom only when first cuepoint starts
      if (nextCuepoint.current === 0) {
        setPosition((position) => ({
          ...position,
          x: window.innerWidth <= 768 ? -43 : -20,
          y:
            window.innerHeight -
            parseInt(height) -
            // config?.styles?.widget?.position_bottom * 3,
            100 * maxBottomPositionIncreaser,
        }));
      }

      if (
        nextCuepoint.current === 0 ||
        hls?.[nextCuepoint.current].highlightDetails.highlight.original_id !==
          hls?.[nextCuepoint.current - 1].highlightDetails.highlight.original_id
      ) {
        // To prevent sending of repeated hls?.[0] to the event, which appears at the end of the media
        if (loopCount !== hls?.length) {
          const highlightDetails = hls?.[nextCuepoint.current].highlightDetails;

          const eventParams = {
            media_time: currentTime,
            highlight_text: highlightDetails.highlight?.content?.text?.slice(
              0,
              50,
            ),
            highlight_index: nextCuepoint.current,
            highlight_element: highlightDetails.element?.tagName,
            highlight_position_x: highlightDetails.position?.x,
            highlight_position_y: highlightDetails.position?.y,
          };

          analytics().logEvent('widget_highlight', eventParams);
        }
      }

      nextCuepoint.current += 1;
      loopCount += 1;
    }
    prevTimestamp.current = timestamp;

    return requestAnimationFrame(mainLoop);
  }, []);
  const animate = () => {
    // Start polling when the component mounts
    return requestAnimationFrame(mainLoop);
  };

  useEffect(() => {
    mediaContext.current.isPlaying = isPlaying;
  }, [isPlaying]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const data = await dataRetrievalStrategy.retrieveData();
        if (data === null) {
          // Publication isn't ready yet
          return;
        }
        const {
          config,
          highlights: retrievedHighlights,
          cuepoints: cuepointsData,
        } = data;
        highlightsData.current = retrievedHighlights;
        const processingResult = processingStrategy.process(
          retrievedHighlights,
          config,
          cuepointsData,
          { scrollHandler: scrollHandler.current },
        );
        if ('highlights' in processingResult) {
          highlights.current = processingResult.highlights;
        } else if ('annotations' in processingResult) {
          annotations.current = processingResult.annotations as {
            [key: string]: RoughAnnotation[];
          };
          highlights.current = processingResult.highlights;
        }
        cuepoints.current = cuepointsData;
        setConfig(config);
        const pageVideo = dataRetrievalStrategy.getMedia();
        setMediaSrc(pageVideo);

        // Inject styles
        const style = document.createElement('style');
        if (config?.debug) {
          style.innerHTML = InjectedStyles({ zIndex: 9999 });
        } else if (config?.styles?.max_zindex) {
          // One less than the widget z-index
          style.innerHTML = InjectedStyles({
            zIndex: config?.styles?.max_zindex - 1,
          });
        }
        const hostContainer = document.querySelector<HTMLElement>(
          '.' + HOST_CLASSNAME,
        );
        if (hostContainer && config.styles?.max_zindex) {
          // update ASAP zindex to avoid widget flickering on top of modal e.g.
          hostContainer.style.zIndex = String(config.styles?.max_zindex);
        }
        document.head.appendChild(style);
      } catch (e) {
        // We could display something to the user, but that would require
        // additional code and styling
        console.error(e);
      } finally {
        // We could display an error message here
        setLoading(false);
      }
    })();
  }, []);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const scrollHandler = useRef<() => void>(() => {
    return;
  });

  const [showBubble, setShowBubble] = useState(false);
  //to show the bubble only once
  const [bubbleShowed, setBubbleShowed] = useState(false);

  useEffect(() => {
    // Pause the video on visibility change
    function visibilityChangeHandler() {
      const isTabActive =
        document.visibilityState === 'visible' && document.hidden === false;
      if (isTabActive) {
        if (wasPlaying.current) {
          avatarRef.current?.media?.play();
          avatarRef.current?.visualizer?.play();
          setIsPlaying(true);
        }
      } else {
        wasPlaying.current = isPlaying;
        avatarRef.current?.media?.pause();
        avatarRef.current?.visualizer?.pause();
        setIsPlaying(false);
      }
    }
    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [isPlaying]);

  useEffect(() => {
    document.addEventListener('scroll', handleScrolling);
    return () => {
      document.removeEventListener('scroll', handleScrolling);
    };
  }, [config]); //to update the config when it is ready

  const visibleDelay = useRef(0);
  const showBubbleTimeout = useRef<ReturnType<typeof setTimeout>>();
  const currentBreakpoint = useCurrentBreakpoint(config);
  const { height } = config?.styles?.widget?.[currentBreakpoint] ?? {
    height: '0',
  };
  useEffect(() => {
    if (loading || !config?.styles?.widget) return;
    // Set the widget into the top right corner based on the configured dimensions
    const hostContainer = document.querySelector<HTMLElement>(
      '.' + HOST_CLASSNAME,
    );
    if (hostContainer) {
      hostContainer.style.width =
        config.styles.widget?.[currentBreakpoint]?.width;
      hostContainer.style.height =
        config.styles.widget?.[currentBreakpoint]?.height;

      hostContainer.style.top = '0';
      hostContainer.style.right = '0';

      if (currentBreakpoint === 'small') {
        handleShowBubble();
      }
      setPosition((position) => ({
        ...position,
        x: config?.styles?.widget?.[currentBreakpoint]?.x,
        y: config?.styles?.widget?.[currentBreakpoint]?.y,
      }));
    }
  }, [config?.styles?.widget, currentBreakpoint, loading]);

  const handleScrolling = useCallback(() => {
    const scrollPercentage =
      window.scrollY / (document.body.scrollHeight - window.innerHeight);

    const scrollSpeedFactor = 1.5;

    let y = window.innerHeight * scrollPercentage * scrollSpeedFactor;
    const marginBottom = 30;

    // Do not let the widget go off the screen and lower than footer
    if (
      y + parseInt(height) >
      window.innerHeight -
        (config?.styles?.widget?.position_bottom ?? 0) -
        marginBottom
    ) {
      y =
        window.innerHeight -
        parseInt(height) -
        (config?.styles?.widget?.position_bottom ?? 0) -
        marginBottom;
    }

    if (config?.styles?.widget?.y && y < config?.styles?.widget?.y) return;

    setPosition((position) => ({
      ...position,
      y,
    }));
  }, [config]);

  const resizeHandler = useCallback(() => {
    const animationHandler = () => {
      ticking.current = false;
      if (!highlights.current) return;
      for (const highlight of highlights.current) {
        highlight.move(); // Recalculate the position of the highlights
      }
    };
    if (!ticking.current) {
      ticking.current = true;
      requestAnimationFrame(animationHandler);
    }
  }, [config]);

  scrollHandler.current = resizeHandler;
  useEffect(() => {
    if (!highlights.current) return;
    showOverlay(3000);
  }, []);

  useEffect(() => {
    const handler = resizeHandler;
    window.addEventListener('resize', handler);
    document.addEventListener('scroll', handler, { passive: true });
    return () => {
      window.removeEventListener('resize', handler);
      document.removeEventListener('scroll', handler);
    };
  }, [resizeHandler]);

  useEffect(() => {
    if (avatarRef.current?.media) {
      animationFrameId.current = animate();
      // not working on mobile safari
    }

    // analytics().logEvent('widget_view', {
    //   page_url: window.location.href,
    // });

    return () => {
      return cancelAnimationFrame(animationFrameId.current);
    };
  }, []);

  // Show bubble on scroll on desktop
  useEffect(() => {
    const showBubbleOnScroll = () => {
      if (currentBreakpoint !== 'small' && !bubbleShowed) {
        handleShowBubble();
      }
    };
    document.addEventListener('scroll', showBubbleOnScroll, { once: true });

    return () => {
      document.removeEventListener('scroll', showBubbleOnScroll);
    };
  }, [bubbleShowed]);

  useEffect(() => {
    if (config) {
      analytics().init({
        shouldTrack: config.enable_analytics ?? false,
        eventParams: {
          organization_id: config.organization_id,
          product_sku: config.product_sku,
          language: config.language,
          app_id: config.app_id,
        },
      });
      analytics().logEvent('widget_view');
    }
  }, [config]);

  function togglePlay() {
    handleHideBubble(true);
    setBubbleShowed(true);

    const media = avatarRef.current?.media ?? avatarRef.current?.visualizer;
    const visualizer = avatarRef.current?.visualizer;
    const currentTime = mediaContext.current?.currentTime;
    playedTillTheEnd.current = false;
    if (!animationFrameId.current) {
      animate();
    }
    if (isPlaying) {
      media?.pause?.();
      visualizer?.pause?.();
      setVisible(true);
      setIsPlaying(false);
      analytics().logEvent('media_pause', {
        media_url: mediaSrc,
        media_time: currentTime,
        media_duration: media?.duration,
        highlight_index: nextCuepoint.current,
      });
    } else {
      hideOverlay();
      media?.play?.();
      visualizer?.play?.();
      setIsPlaying(true);
      analytics().logEvent('media_play', {
        media_url: mediaSrc,
        media_time: currentTime,
        media_duration: media?.duration,
        highlight_index: nextCuepoint.current,
      });
    }
  }

  const showOverlay = (delay = 500) => {
    visibleDelay.current = setTimeout(() => {
      setVisible(true);
    }, delay);
  };

  const hideOverlay = () => {
    clearTimeout(visibleDelay.current);
    setVisible(false);
  };

  const handleShowBubble = () => {
    if (bubbleShowed) return;

    const delay = currentBreakpoint === 'small' ? 3000 : 0;

    // Set the timeout for showing the bubble
    showBubbleTimeout.current = setTimeout(() => {
      setShowBubble(true);
      setTimeout(() => {
        setShowBubble(false);
      }, 5000);
    }, delay);
  };

  const dismissBubble = () => {
    setShowBubble(false);
  };
  const handleHideBubble = (dismiss = false) => {
    setShowBubble(false);
    // if playing already video for instance
    if (dismiss) dismissBubble();

    // Clear the timeouts to prevent the bubble from showing
    if (showBubbleTimeout.current) {
      clearTimeout(showBubbleTimeout.current);
      showBubbleTimeout.current = undefined;
    }
  };

  function handleDragStop(e: DraggableEvent, data: DraggableData) {
    e.stopPropagation();
    if (
      Math.abs(data.x - position.x) < Math.abs(5) &&
      Math.abs(data.y - position.y) < Math.abs(5) &&
      position.clickedInCircle
    ) {
      togglePlay();
    }
    try {
      analytics().logEvent('widget_drag', {
        position_x: data.x,
        position_y: data.y,
        position_origin_x: position.x,
        position_origin_y: position.y,
        media_time: currentTime,
        highlight_index: nextCuepoint.current,
      });
    } catch (e) {
      console.error(e);
    }
    setPosition({ ...position, x: data.x, y: data.y });
  }
  function handleDragStart(e: DraggableEvent, data: DraggableData) {
    const container = containerRef.current;
    if (!container) return;
    const { x, y } = getCoordsInElement(container, e);
    const Px = x;
    const Py = y;
    // TODO: Make this configurable, otherwise clicking inside the
    //  circle is borked
    const radius = 100; // Circle radius
    const Cx = 100; // Circle center X
    const Cy = 100; // Circle center Y

    const isInside = isPointInCircle(Px, Py, Cx, Cy, radius);
    setPosition({
      x: data.x,
      y: data.y,
      clickedInCircle: isInside,
    });
  }

  const handlePoweredByClick = () => {
    analytics().logEvent('widget_poweredby_click', {
      media_time: currentTime,
      highlight_index: nextCuepoint.current,
    });
  };
  const playButtonHeight = currentBreakpoint === 'small' ? '10px' : '18px';
  const playButtonWidth = currentBreakpoint === 'small' ? '5px' : '9px';
  const isVisualizer = config?.widget_type === 'visualizer';
  const playButtonColor =
    config?.colors?.play_button ?? config?.colors?.primary ?? '#000000';
  const css = `

  .widget-wrapper {
    opacity: 0;
    transition: opacity 1s;
    ${config?.styles?.widget?.font_family && `font-family: ${config?.styles?.widget?.font_family}, sans-serif;`}
  }
  .widget-wrapper.ready {
    opacity: 1;
    transition: opacity 1s;
  }
  .widget-container {
    user-select: none;
  }
  canvas {
    border-radius: 50%
  }
  .powered-by {
    display: block;
    text-decoration: none;
    position: absolute;
    bottom: -14px;
    z-index: 22;
    font-size: ${currentBreakpoint === 'small' ? '8px' : '10px'};
    color: #606060;
  // background: #ffffff88;
  // background: linear-gradient(to bottom, rgba(256,256,256,0) 0%, rgba(256,256,256,1) 100%);
  // padding: 1px 3px;
  // border-radius: 10px;
  }
  .debug {
    position: absolute;
    color: red;
    font-size: 14;
    top: 0;
    right: 0;
  }

  .playpause label {
    pointer-events: none;
    display: block;
    box-sizing: border-box;
    width: 0;
    height: ${playButtonHeight};
    border-color: transparent transparent transparent ${playButtonColor};
    transition: 100ms all ease ${isVisualizer ? ';' : ', opacity 1ms none;'}
    cursor: pointer;
    border-style: double;
    border-width: 0px 0 0px ${playButtonHeight};
    opacity: ${isVisualizer ? 1 : 0}
  }
  .playpause input[type="checkbox"] {
    position: absolute;
    left: -9999px;
  }
  .playpause input[type="checkbox"]:checked + label {
    border-style: solid;
    opacity: 1;
    border-width: ${playButtonWidth} 0 ${playButtonWidth} ${playButtonHeight};
  }`;

  const contextValue = {
    config,
  };

  return (
    <GlobalContextProvider value={contextValue}>
      <style>
        {css}
        {config?.styles?.root_styles}
      </style>
      <Draggable
        position={position}
        onStart={handleDragStart}
        onStop={handleDragStop}
      >
        <div
          className={`widget-wrapper ${!loading ? 'ready' : ''}`}
          style={{
            ...DEFAULT_WIDGET_STYLE,
            ...config?.styles?.widget,
            zIndex: 3,
          }}
          role="application"
          onMouseEnter={() => {
            showOverlay();
            handleShowBubble();
            analytics().logEvent('widget_hover');
          }}
          onMouseLeave={() => {
            hideOverlay();
            handleHideBubble();
          }}
        >
          {loading || !config ? (
            <div>
              {/* <LoadingIndicator
                width={config?.styles?.widget?.width ?? 200}
                height={config?.styles?.widget?.height ?? 200}
              /> */}
            </div>
          ) : (
            <>
              {visible && config?.components?.controls?.enabled && (
                <div
                  id="controls"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    background:
                      'linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%)',
                    transition: 'opacity 0.5s ease-in-out',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'visible',
                    borderRadius: '50%',
                    zIndex: '100',
                    transform: 'translate(-50%, -50%)',
                    left: '50%',
                    top: '50%',
                    ...config?.styles?.widget_controls,
                  }}
                >
                  <Controls
                    toggleVideo={togglePlay}
                    playButtonText={
                      config?.components.controls.play_button_text
                    }
                  />
                </div>
              )}
              <div
                className={'widget-container'}
                ref={containerRef}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  // backdropFilter: 'blur(3px)',
                  zIndex: 21,
                  flex: '0 0 ' + config?.styles?.widget?.width,
                  pointerEvents: 'all',
                }}
              >
                <Avatar
                  mediaSrc={mediaSrc}
                  type={config?.widget_type}
                  mediaStyle={{
                    width: '100%', // Scale the video to fit the container
                    height: 'auto',
                    position: 'absolute',
                    top: 0,
                    ...dataRetrievalStrategy.getMediaStyle(),
                  }}
                  ref={avatarRefCallback}
                />
                {isPlaying ? (
                  config?.widget_type === 'visualizer' && (
                    <>
                      <div
                        style={{
                          cursor: 'pointer',
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: -2,
                          left: 0,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          transform: 'opacity 1s ease-in-out',
                          opacity: 0.8,
                        }}
                      >
                        <PauseIcon
                          size={currentBreakpoint === 'small' ? 16 : 21}
                          color={
                            config?.colors?.play_button ??
                            config?.colors?.primary ??
                            '#DB0581'
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: -2,
                          left: 0,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          transform: 'opacity 1s ease-in-out',
                        }}
                      >
                        <PauseIcon
                          size={currentBreakpoint === 'small' ? 19 : 27}
                          color={
                            config?.colors?.play_button ??
                            config?.colors?.primary ??
                            '#DB0581'
                          }
                          style={{
                            opacity: 0.6,
                            filter: 'blur(5px)',
                          }}
                        />
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: -2,
                        left: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        transform: 'opacity 1s ease-in-out',
                      }}
                    >
                      <PlayIcon
                        size={currentBreakpoint === 'small' ? 13 : 18}
                        color={
                          config?.colors?.play_button ??
                          config?.colors?.primary ??
                          '#DB0581'
                        }
                      />
                    </div>

                    <div
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: -2,
                        left: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        transform: 'opacity 1s ease-in-out',
                      }}
                    >
                      <PlayIcon
                        size={currentBreakpoint === 'small' ? 17 : 25}
                        color={
                          config?.colors?.play_button ??
                          config?.colors?.primary ??
                          '#DB0581'
                        }
                        style={{
                          opacity: 0.6,
                          filter: 'blur(5px)',
                        }}
                      />
                    </div>
                  </>
                )}
                {window?.[HEY_GENIUS_FIELD]?.debug && (
                  <div className="debug">
                    {Math.round(displayedCurrentTime)}
                  </div>
                )}
                <a
                  onClick={handlePoweredByClick}
                  className="powered-by"
                  target="_blank"
                  href="https://www.libertify.com"
                >
                  {config?.components?.powered_by?.[config.language]}{' '}
                  <span style={{ fontWeight: 'bold' }}>Libertify</span>
                </a>
              </div>
              {config?.components?.bubble && (
                <Bubble
                  mobile={currentBreakpoint === 'small'}
                  show={showBubble}
                  config={config}
                />
              )}
              <Overlay
                id="overlay1"
                color={config?.colors?.gradient[0]}
                style={{ transform: 'translate(-20%, 10%)' }}
              />
              <Overlay
                id="overlay2"
                color={config?.colors?.gradient[1]}
                style={{ transform: 'translate(20%, 10%)' }}
              />
              <Overlay
                id="overlay3"
                color={config?.colors?.gradient[2]}
                style={{ transform: 'translateY(-20%)' }}
              />
            </>
          )}
        </div>
      </Draggable>
    </GlobalContextProvider>
  );
}
