import { GENIUS_API_URL, HEY_GENIUS_FIELD, WIDGET_BASE } from 'src/constants';
import highlights, { configs, videos } from './highlights';
import {
  DataRetrievalStrategy,
  Publication,
  THighlight,
  WidgetConfig,
} from 'src/types';
import { CSSProperties } from 'preact/compat';

type URLDictionary = {
  config: string;
  asset: string;
  publication: string;
};
export class URLBuilder {
  public static build(
    url: string,
    options: {
      organization_id: string;
      product_sku: string;
      assets?: string[];
      language?: string;
      alt_config_url?: string;
      alt_publication_base_url?: string;
      widget_base?: string;
    },
  ): URLDictionary {
    const theUrl = this.buildUrl(url, options);
    return {
      config: this.config(theUrl, options),
      asset: this.asset(theUrl),
      publication: this.publication(theUrl, options),
    };
  }

  private static buildUrl(
    url,
    _options: Parameters<typeof URLBuilder.build>[1],
  ) {
    // Not of much use for now, but could be useful later
    return url;
  }

  private static asset(baseUrl) {
    return `${baseUrl}`;
  }

  private static config(baseUrl, options) {
    if (options.alt_config_url) {
      return `${options.alt_config_url}`;
    }
    if (options.widget_base) {
      return `${options.widget_base}/data/organizations/${options.organization_id}/${options.app_id}/config.json`;
    }
    return `${baseUrl}/config.json`;
  }
  private static publication(baseUrl, options) {
    let theBaseUrl = baseUrl;
    if (options.alt_publication_base_url) {
      theBaseUrl = options.alt_publication_base_url;
    }
    return `${theBaseUrl}/media/organizations/${options.organization_id}/publications/public?product_sku=${options.product_sku}&language=${options.language ?? 'en-US'}`;
  }
}

abstract class BaseDataRetrievalStrategy implements DataRetrievalStrategy {
  public abstract retrieveData(): Promise<{
    highlights: THighlight[];
    config: WidgetConfig;
    assets?: unknown[];
    cuepoints: any;
  } | null>;
  public abstract getMedia(): string;
  public abstract getMediaStyle(): CSSProperties;
}

export class LocalDataRetrievalStrategy extends BaseDataRetrievalStrategy {
  private readonly pageId = window.location.pathname.split('/').pop();
  private readonly legacyPageId = window.location.pathname;
  public async retrieveData() {
    const { pageId } = this;
    return Promise.resolve({
      highlights: highlights[this.pageId] ?? highlights[this.legacyPageId],
      config: configs[pageId] ?? configs[this.legacyPageId],
    });
  }

  public getMedia() {
    const config = configs[this.pageId] ?? configs[this.legacyPageId];
    const { selectedVideo } = config;
    if (!videos[this.pageId]) {
      throw new Error('No videos found for this page');
    }
    if (Array.isArray(videos[this.pageId])) {
      return (
        videos[this.pageId]?.[selectedVideo] ??
        videos[this.legacyPageId]?.[selectedVideo]
      );
    } else {
      return videos[this.pageId] ?? videos[this.legacyPageId];
    }
  }

  public getMediaStyle() {
    const config = configs[this.pageId] ?? configs[this.legacyPageId];
    if (!config.styles) {
      return {};
    }
    return config?.styles?.video[config.selectedVideo] ?? config.styles.video;
  }
}

// Retrieves and stores the config for the widget
export class ApiDataRetrievalStrategy extends BaseDataRetrievalStrategy {
  private config: WidgetConfig;
  private readonly urls: URLDictionary;
  constructor(url: string = GENIUS_API_URL) {
    super();
    this.config = this.getValuesFromWindow();

    this.urls = URLBuilder.build(url, {
      ...this.config,
      organization_id: this.config.organization_id,
    });
  }

  private getValuesFromWindow() {
    const WINDOW_CONFIGURED_VALUES: [string, boolean?][] = [
      // [1] means that the value is required
      ['app_id'],
      ['width'],
      ['product_sku', true],
      ['variation_id'],
      ['widget_base'],
      ['datasource_base'],
      ['organization_id', true],
      ['language'],
      ['debug'],
    ];
    const config: Partial<WidgetConfig> = {
      widget_base: WIDGET_BASE,
      app_id: 'default',
      language: 'en-US',
      components: {
        powered_by: {
          // FIXME not working when not defined in config.json also !!!
          'fr-FR': 'par',
          'en-US': 'by',
        },
      },
    };
    for (const [key, required] of WINDOW_CONFIGURED_VALUES) {
      const value = window?.[HEY_GENIUS_FIELD]?.[key];
      if (!value && required) {
        throw new Error(`window.${HEY_GENIUS_FIELD} is missing ${key}`);
      }
    }
    Object.assign(config, window?.[HEY_GENIUS_FIELD]);
    if (config.debug) console.debug('Config:', config);
    return config as WidgetConfig;
  }
  private async fetchPublication(): Promise<Publication | null> {
    try {
      const response = await fetch(this.urls.publication);
      if (response.status === 204) {
        return null;
      }
      if (!response.ok)
        throw new Error(
          `Received response with status code ${response.status} when fetching publication`,
        );
      return await response.json();
    } catch {
      throw new Error(
        `Could not fetch publication from ${this.urls.publication}`,
      );
    }
  }

  private async fetchAssets(): Promise<unknown[] | null> {
    const { config } = this;
    if (!config) throw new Error('No config provided!');
    if (!config.assets?.length) return null;
    const promises = config.assets?.map((asset) =>
      fetch(`${this.urls.asset}/${asset}`).then((response) => response.json()),
    );
    return promises;
  }

  private async fetchProjectConfiguration(): Promise<WidgetConfig | null> {
    // @ts-expect-error This is deprecated legacy configuration
    const legacyConfig = window?.libertify?.config;
    if (legacyConfig) {
      return Promise.resolve(this.config);
    }
    if (!this.urls.config) throw new Error('No config URL provided');
    const response = await fetch(this.urls.config);
    this.config = { ...this.config, ...(await response.json()) };
    return this.config;
  }

  private async fetchHighlights(highlightUrl): Promise<THighlight[]> {
    if (!highlightUrl) throw new Error('No highlight URL provided');
    const response = await fetch(highlightUrl);
    if (!response.ok)
      throw new Error(
        `Received response with status code ${response.status} when fetching highlights`,
      );
    const { data } = await response.json();
    return data;
  }

  private async fetchCuepoints(cuepointsUrl) {
    if (!cuepointsUrl) return [];
    const response = await fetch(cuepointsUrl);
    return (await response.json()).data;
  }

  private async createDataStructure(publication) {
    if (publication) {
      this.config.assets = Object.values(publication.assets).map(
        (asset) => (asset as { path: string }).path,
      );
      if (!publication.assets['Highlights_0']) {
        throw new Error('No highlights found');
      } else if (!publication.assets['CuePoints_0']) {
        throw new Error('No cuepoints found');
      }
      const results = await Promise.all([
        this.fetchHighlights(publication.assets['Highlights_0'].path),
        // this.fetchAssets(),
        this.fetchCuepoints(publication.assets['CuePoints_0'].path),
      ]);
      const [highlights, /* assets , */ cuepoints] = results;
      if (this.config.debug) console.debug('data', { highlights, cuepoints });
      return {
        config: this.config,
        highlights,
        assets: /* assets ??  */ [],
        cuepoints,
      };
    } else if (this.config.alt_highlights_url) {
      console.log(await this.fetchHighlights(this.config.alt_highlights_url));
      return {
        config: this.config,
        highlights: await this.fetchHighlights(this.config.alt_highlights_url),
        assets: [],
        cuepoints: [],
      };
    } else {
      throw new Error(
        'No publication found and no alternative highlights url provided',
      );
    }
  }

  public async retrieveData(): Promise<{
    highlights: THighlight[];
    config: WidgetConfig;
    assets: unknown[];
    cuepoints: any;
  } | null> {
    await this.fetchProjectConfiguration();
    if (!this.config) throw new Error('Error retrieving config');
    // @ts-expect-error This is deprecated legacy configuration
    const legacyConfig = window?.libertify?.config;
    if (legacyConfig) {
      return {
        config: this.config,
        highlights: legacyConfig ?? [],
        assets: [],
        cuepoints: [],
      };
    }
    let publication: Publication | null = null;
    try {
      publication = await this.fetchPublication();
      if (publication === null) {
        return null;
      }
    } catch (e) {
      if (this.config.alt_highlights_url) {
        console.warn(
          'Could not fetch publication, but alternative highlights url present; continuing',
        );
      } else {
        throw e;
      }
    }
    if (!this.config.media_type) {
      throw new Error('No media type provided in the config');
    }
    this.config.media = this.config.media_url ?? publication?.media.url;
    return this.createDataStructure(publication);
  }

  public getMedia() {
    if (!this.config?.media) throw new Error('No config for media provided');
    if (Array.isArray(this.config.media)) {
      return this.config.media[this.config.selected_media];
    }
    return this.config.media;
  }
  public getMediaStyle() {
    if (!this.config?.styles?.media)
      throw new Error('No config for styles provided');
    return this.config.styles.media[this.config.selected_media];
  }
}
