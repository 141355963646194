import { useEffect, useImperativeHandle, useRef } from 'preact/hooks';
import { forwardRef } from 'preact/compat';

import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  MeshBasicMaterial,
  Mesh,
  AudioListener,
  Audio,
  AudioLoader,
  SphereGeometry,
  SRGBColorSpace,
  DirectionalLight,
  TorusGeometry,
  AudioAnalyser,
  CanvasTexture,
  AmbientLight,
} from 'three';
import {
  BlendFunction,
  EffectComposer,
  EffectPass,
  RenderPass,
  SelectiveBloomEffect,
} from 'postprocessing';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default forwardRef(function Visualizer(
  {
    audioLink,
    width,
    height,
  }: { audioLink: string; width: number; height: number },
  ref,
) {
  const canvasRef = useRef(null);
  const audio = useRef<Audio<GainNode>>(null);
  const duration = useRef(0);
  const dimensionRef = useRef({ width, height });
  // Initialize time variable for animation
  const time = useRef(0);
  useEffect(() => {
    if (!canvasRef.current) return;
    // Set up scene, camera, and renderer
    const scene = new Scene();
    const camera = new PerspectiveCamera(75, width / height, 0.1, 1000);
    const renderer = new WebGLRenderer({
      canvas: canvasRef.current,
      powerPreference: 'high-performance',
      stencil: false,
      antialias: false,
      depth: false,
    });
    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 0);
    const ambientLight = new AmbientLight(0xb8b8b8);
    const mainLight = new DirectionalLight(0xffffff, 3);
    mainLight.position.set(-1, 1, 1);

    scene.add(ambientLight, mainLight);
    // Create gradient texture
    const canvasSphere = document.createElement('canvas');
    canvasSphere.width = 128;
    canvasSphere.height = 128;
    const contextSphere = canvasSphere.getContext('2d');
    if (!contextSphere) throw new Error('Could not get 2d context');
    // Create gradient
    const gradientSphere = contextSphere.createRadialGradient(
      canvasSphere.width / 2,
      canvasSphere.height / 2,
      0,
      canvasSphere.width / 2,
      canvasSphere.height / 2,
      canvasSphere.width / 2,
    );

    // gradientSphere.addColorStop(0, '#f00'); // Hot pink
    // gradientSphere.addColorStop(1, '#00f'); // Light pink

    // Fill canvas with gradient
    contextSphere.fillStyle = gradientSphere;
    contextSphere.fillRect(0, 0, canvasSphere.width, canvasSphere.height);

    const circleGeometry = new SphereGeometry(1.3, 64, 64); // Radius of 5, 64 segments
    const circleMaterial = new MeshBasicMaterial({
      // color: 0x808080, // Pink color
      opacity: 0.15,
      transparent: true,
      depthWrite: false,
      map: new CanvasTexture(canvasSphere),
    });
    const circleMesh = new Mesh(circleGeometry, circleMaterial);

    // circleMesh.position.z = 3;
    scene.add(circleMesh);

    renderer.outputColorSpace = SRGBColorSpace;
    const renderScene = new RenderPass(scene, camera);
    const bloomEffect = new SelectiveBloomEffect(scene, camera, {
      blendFunction: BlendFunction.ADD,
      mipmapBlur: true,
      luminanceThreshold: 0.15,
      luminanceSmoothing: 0.3,
      intensity: 4, // To make the bloom bright (0-10)
      radius: 0.5,
    });
    bloomEffect.mipmapBlurPass.radius = 0.5;
    bloomEffect.luminancePass.enabled = true;
    const bloomPass = new EffectPass(camera, bloomEffect);
    bloomEffect.selection.add(circleMesh);
    bloomEffect.inverted = true;
    const bloomComposer = new EffectComposer(renderer, { multisampling: 8 });
    bloomComposer.addPass(renderScene);
    bloomComposer.addPass(bloomPass);

    // const outputPass = new OutputPass();
    // bloomComposer.addPass(outputPass);

    // Handle window resize
    const onWindowResize = () => {
      const { width, height } = dimensionRef.current;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      bloomComposer.setSize(width, height);
    };

    window.addEventListener('resize', onWindowResize);

    // Create the AudioListener and Audio objects
    const audioListener = new AudioListener();
    camera.add(audioListener);
    const sound = new Audio(audioListener);
    audio.current = sound;

    // Now, load the audio file and play when ready
    const audioLoader = new AudioLoader();
    audioLoader.load(audioLink, function (buffer) {
      duration.current = buffer.duration;
      sound.setBuffer(buffer);
    });

    const analyzer = new AudioAnalyser(sound, 32);

    // Resume the audio context if it is suspended (required if we need autoplay on load)
    // const audioContext = audioListener.context;
    // if (audioContext.state === 'suspended') {
    //   audioContext.resume();
    // }

    // Create gradient texture
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const context = canvas.getContext('2d');

    // Create gradient
    if (!context) {
      throw new Error('Could not get 2d context');
    }
    const gradient = context.createRadialGradient(
      canvas.width / 2,
      canvas.height / 2,
      0,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
    );

    gradient.addColorStop(0, '#f2c3d2'); // Hot pink
    gradient.addColorStop(1, '#f2c3d2'); // Light pink

    // Fill canvas with gradient
    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Create a 3D Sphere (SphereGeometry)
    const geometry = new SphereGeometry(1.0, 128, 128); // Radius of 5, 64 segments

    const texture = new CanvasTexture(canvas);
    const material = new MeshBasicMaterial({
      color: 0xf2c3d2,
      opacity: 0.6,
      transparent: true,
      map: texture,
    });
    const sphereMesh = new Mesh(geometry, material);
    // Outer sphere is not animated
    const outerSphere = sphereMesh.clone();
    const outerSphereScaleFactor = 1.1;
    outerSphere.scale.set(
      outerSphereScaleFactor,
      outerSphereScaleFactor,
      outerSphereScaleFactor,
    );
    bloomEffect.selection.add(outerSphere);
    scene.add(outerSphere);
    scene.add(sphereMesh);
    // Create a thick ellipse (torus) around the X-axis (green)
    const torusGeometryX = new TorusGeometry(1.2, 0.04, 16, 100); // radius 5, tube thickness 0.2
    const torusMaterialX = new MeshBasicMaterial({
      color: 0x33a328, // Green color
      opacity: 0.8,
    });
    const torusMeshX = new Mesh(torusGeometryX, torusMaterialX);
    scene.add(torusMeshX);

    // Create a thick ellipse (torus) around the Y-axis (pink)
    const torusGeometryY = new TorusGeometry(1.2, 0.04, 16, 100); // radius 5, tube thickness 0.2
    const torusMaterialY = new MeshBasicMaterial({
      color: 0xcb006d, // Pink color
    });
    const torusMeshY = new Mesh(torusGeometryY, torusMaterialY);
    scene.add(torusMeshY);

    // Set initial camera position
    camera.position.z = 3;

    // Set up OrbitControls for mouse interaction
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Smooth camera movements
    controls.dampingFactor = 0.25; // Adjust damping speed
    controls.screenSpacePanning = false; // Don't allow panning up/down
    controls.maxPolarAngle = Math.PI / 2; // Prevent camera from going under the sphere

    // Add lighting to the scene

    torusMeshX.rotation.y = 1; // Rotate the green torus around the X-axis
    torusMeshY.rotation.x = 1; // Rotate the green torus around the X-axis

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      const averageFrequency = analyzer.getAverageFrequency();

      // For Sphere animation
      const scaleFactor = 0.8 + averageFrequency / 512;
      sphereMesh.scale.set(scaleFactor, scaleFactor, scaleFactor);
      // For bloomed Circle animation
      // const scaleFactor = 0.65 + averageFrequency / 1024;
      // circleMesh.scale.set(scaleFactor, scaleFactor, scaleFactor);

      // Sinusoidal movement for the green torus (move from bottom to top)
      // torusMeshX.position.y = 1.2 * Math.sin(time); // Moves between -2 and +2 on the Y-axis

      // // Sinusoidal movement for the pink torus (move from top to bottom)
      // torusMeshY.position.x = 1.2 * Math.cos(time); // Moves between -1 and +1 on the Y-axis

      // Rotate the green torus around the X-axis
      torusMeshX.rotation.x += 0.001; // Rotate around the X-axis

      // Rotate the pink torus around the Y-axis
      torusMeshY.rotation.y += 0.0015; // Rotate around the Y-axis

      // sphereMesh.rotation.y += 0.01;
      sphereMesh.rotation.z += 0.01;
      // bloomEffect.selection.add(sphereMesh);
      // Selection is inverted, so we add meshes that should not be affected by the effect here
      bloomEffect.selection.add(torusMeshX);
      bloomEffect.selection.add(torusMeshY);
      bloomEffect.ignoreBackground = true;
      time.current += 0.005;

      // Update the controls (for smooth movement)
      controls.update();

      // Render the scene from the camera's perspective
      // renderer.render(scene, camera);
      renderer.setPixelRatio(window.devicePixelRatio);
      bloomComposer.render();
    };

    animate();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', onWindowResize);
      renderer.dispose();
      bloomComposer.dispose();
      sphereMesh.geometry.dispose();
      sphereMesh.material.dispose();
      torusMeshX.geometry.dispose();
      torusMeshX.material.dispose();
      torusMeshY.geometry.dispose();
      torusMeshY.material.dispose();
      circleMesh.geometry.dispose();
      circleMesh.material.dispose();
      outerSphere.geometry.dispose();
      outerSphere.material.dispose();
    };
  }, [audioLink, height, width]);

  useEffect(() => {
    dimensionRef.current = { width, height };
  }, [width, height]);

  useImperativeHandle(ref, () => {
    return {
      play: () => {
        audio.current?.play();
      },
      pause: () => {
        audio.current?.pause();
      },
      stop: () => {
        audio.current?.stop();
      },
      duration: duration.current,
    };
  }, []);

  return (
    <div style={{ position: 'relative', width, height }}>
      <canvas
        ref={canvasRef}
        style={{
          pointerEvents: 'none',
          width,
          height,
        }}
      />
    </div>
  );
});
